export default [
  {
    key: 'gameName',
    label: 'field.game',
    thClass: 'w-50px',
  },
  {
    key: 'invoiceNo',
    label: 'field.invoice',
    thClass: 'w-50px',
  },
  {
    key: 'amount',
    label: 'field.amount',
  },
  {
    key: 'username',
    label: 'field.username',
  },
  {
    key: 'jackpotType',
    label: 'field.prize',
  },
  {
    key: 'createdAt',
    label: 'field.date',
  },
]
