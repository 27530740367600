export default [
  {
    title: 'breadcrumb.baccarat',
    header: 'breadcrumb.baccarat',
    icon: '',
    children: [
      {
        title: 'breadcrumb.baccaratLive',
        route: 'baccarat-live',
        icon: '',
        resource: 'baccarat-live',
        action: 'read',
      },
      {
        title: 'breadcrumb.baccaratResult',
        route: 'list-baccarat-result',
        icon: '',
        resource: 'baccarat-result',
        action: 'read',
      },
    ],
  },
]
