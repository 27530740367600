import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import errors from './routes/errors'
import auth from './routes/auth'
import profile from './routes/profile'
import user from './routes/user'
import role from './routes/role'
import liveBetting from './routes/liveBetting'
import parley from './routes/parley'
import company from './routes/company'
import senior from './routes/senior'
import master from './routes/master'
import agent from './routes/agent'
import player from './routes/player'
import transaction from './routes/transaction'
import balanceReport from './routes/balanceReport'
import cockBalanceReport from './routes/cockBalanceReport'
import baccaratBalanceReport from './routes/baccaratBalanceReport'
import parleyReport from './routes/parleyReport'
import requestDeposit from './routes/requestDeposit'
import requestWithdraw from './routes/requestWithdraw'
import betSetting from './routes/betSetting'
import baccaratLive from './routes/baccaratLive'
import baccaratResult from './routes/baccaratResult'
import slotBalanceReport from './routes/slotBalanceReport'
import assistant from './routes/assistant'
import reward from './routes/reward'
import pointRule from './routes/pointRule'
import claimRewardHistory from './routes/claimRewardHistory'
import balanceReportVip from './routes/balanceReportVip'
import lottoBalanceReport from './routes/lottoBalanceReport'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'breadcrumb.home',
        resource: 'home',
        breadcrumb: [
          {
            text: 'breadcrumb.home',
            active: true,
          },
        ],
      },
    },
    ...errors,
    ...auth,
    ...profile,
    ...user,
    ...role,
    ...liveBetting,
    ...parley,
    ...company,
    ...senior,
    ...master,
    ...agent,
    ...player,
    ...assistant,
    ...transaction,
    ...balanceReport,
    ...balanceReportVip,
    ...cockBalanceReport,
    ...baccaratBalanceReport,
    ...lottoBalanceReport,
    ...parleyReport,
    ...requestDeposit,
    ...requestWithdraw,
    ...betSetting,
    ...baccaratLive,
    ...baccaratResult,
    ...slotBalanceReport,
    ...reward,
    ...pointRule,
    ...claimRewardHistory,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// Method using PUSH
const RouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return RouterPush.call(this, to).catch(err => err)
}

// Method using Replace
const RouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(to) {
  return RouterReplace.call(this, to).catch(err => err)
}

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'login' })

    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? 'admin' : null))
  }

  return next()
})

export default router
