export default [
  {
    key: 'isClaimPayback',
    label: 'field.notClaimCommission',
    rules: '',
    type: 'checkbox',
    cols: 6,
    skip: true,
  },
]
