export default [
  {
    key: 'name',
    label: 'field.name',
    rules: 'required',
    type: 'text',
  },
  {
    key: 'phone',
    label: 'field.phoneNumber',
    rules: 'required',
    type: 'tel',
  },
  {
    key: 'channelIds',
    label: 'field.channel',
    rules: '',
    type: 'nAsynMultiSelection',
    repository: 'channel',
    selectionKey: 'id',
    selectionLabel: 'channelName',
  },
  {
    key: 'loginTimeout',
    label: 'field.loginTimeout',
    rules: 'required|integer',
    type: 'text',
  },
  {
    key: 'state',
    label: 'field.an',
    rules: 'required',
    type: 'text',
  },
  {
    key: 'accrueDepositPeriod',
    label: 'field.commissionDropPeriod',
    rules: '',
    type: 'text',
    resource: 'bet-setting',
    action: 'update',
  },
  {
    key: 'commissionPayback',
    label: 'field.commissionPayback',
    rules: 'decimal:2',
    type: 'currency',
    resource: 'bet-setting',
    action: 'update',
  },
  {
    key: 'isPayback',
    label: 'field.isPaybackCommission',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
    resource: 'bet-setting',
    action: 'update',
  },
  {
    key: 'isAccrue',
    label: 'field.haveCommission',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
    resource: 'bet-setting',
    action: 'update',
  },
  {
    label: 'breadcrumb.sabong',
    icon: 'TvIcon',
    type: 'divider',
    resource: 'bet-setting',
    action: 'update',
  },
  {
    key: 'commissionAccrueCock',
    label: 'field.commissionAmount',
    rules: 'required|decimal:2',
    type: 'currency',
    resource: 'bet-setting',
    action: 'update',
  },
  {
    key: 'amountAccrueCock',
    label: 'field.amount',
    rules: 'required|decimal:2',
    type: 'currency',
    resource: 'bet-setting',
    action: 'update',
  },
  {
    label: 'breadcrumb.baccarat',
    icon: 'TvIcon',
    type: 'divider',
    resource: 'bet-setting',
    action: 'update',
  },
  {
    key: 'commissionAccrueBacar',
    label: 'field.commissionAmount',
    rules: 'required|decimal:2',
    type: 'currency',
    resource: 'bet-setting',
    action: 'update',
  },
  {
    key: 'amountAccrueBacar',
    label: 'field.amount',
    rules: 'required|decimal:2',
    type: 'currency',
    resource: 'bet-setting',
    action: 'update',
  },
  {
    label: 'breadcrumb.lotto',
    icon: 'TvIcon',
    type: 'divider',
    resource: 'bet-setting',
    action: 'update',
  },
  {
    key: 'commissionAccrueLotto',
    label: 'field.commissionAmount',
    rules: 'required|decimal:2',
    type: 'currency',
    resource: 'bet-setting',
    action: 'update',
  },
  {
    key: 'amountAccrueLotto',
    label: 'field.amount',
    rules: 'required|decimal:2',
    type: 'currency',
    resource: 'bet-setting',
    action: 'update',
  },
  {
    label: 'breadcrumb.channel1',
    icon: 'TvIcon',
    type: 'divider',
  },
  {
    key: 'minBetTime',
    label: 'field.minBetTime',
    rules: 'decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetTime',
    label: 'field.maxBetTime',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetGame',
    label: 'field.maxBetGame',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxDrawBetGame',
    label: 'field.maxDrawBetGame',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxWinDay',
    label: 'field.maxWinDay',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    label: 'breadcrumb.channel2',
    icon: 'TvIcon',
    type: 'divider',
  },
  {
    key: 'minBetTimeTwo',
    label: 'field.minBetTime',
    rules: 'decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetTimeTwo',
    label: 'field.maxBetTime',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetGameTwo',
    label: 'field.maxBetGame',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetDrawPerGameTwo',
    label: 'field.maxDrawBetGame',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxWinDayTwo',
    label: 'field.maxWinDay',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    label: 'breadcrumb.baccarat1',
    icon: 'TvIcon',
    type: 'divider',
  },
  {
    key: 'minBetTimeBacar',
    label: 'field.minBetTimeBacar',
    rules: 'decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetTimeBacar',
    label: 'field.maxBetTimeBacar',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetGameBacar',
    label: 'field.maxBetGameBacar',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetDrawPerGameBacar',
    label: 'field.maxDrawBetGameBacar',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxWinDayBacar',
    label: 'field.maxWinDayBacar',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'commissionBacar',
    label: 'field.commissionPercentage',
    rules: 'required|max_value:100|decimal:2',
    type: 'currency',
  },
  {
    key: 'increaseBetRateBacarOne',
    label: 'field.increaseBetRateBacar',
    rules: 'max_value:100|decimal:2',
    type: 'currency',
    resource: 'bet-setting',
    action: 'update',
  },
  {
    label: 'breadcrumb.baccarat2',
    icon: 'TvIcon',
    type: 'divider',
  },
  {
    key: 'minBetTimeBacarTwo',
    label: 'field.minBetTimeBacar',
    rules: 'decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetTimeBacarTwo',
    label: 'field.maxBetTimeBacar',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetGameBacarTwo',
    label: 'field.maxBetGameBacar',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetDrawPerGameBacarTwo',
    label: 'field.maxDrawBetGameBacar',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxWinDayBacarTwo',
    label: 'field.maxWinDayBacar',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'commissionBacarTwo',
    label: 'field.commissionPercentage',
    rules: 'required|max_value:100|decimal:2',
    type: 'currency',
  },
  {
    key: 'increaseBetRateBacarTwo',
    label: 'field.increaseBetRateBacar',
    rules: 'max_value:100|decimal:2',
    type: 'currency',
    resource: 'bet-setting',
    action: 'update',
  },
  {
    label: 'breadcrumb.parley',
    icon: 'TvIcon',
    type: 'divider',
  },
  {
    key: 'maxBetAmountParley',
    label: 'field.maxBetAmountParley',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetAmountSingleParley',
    label: 'field.maxBetAmountSingleParley',
    rules: 'required|decimal:2',
    type: 'text',
  },
  {
    label: 'breadcrumb.slotMachine',
    icon: 'TvIcon',
    type: 'divider',
  },
  {
    key: 'maxWinDayJoker',
    label: 'field.maxWinDay',
    rules: 'decimal:2',
    type: 'currency',
  },
  {
    key: 'maxLoseDayJoker',
    label: 'field.maxLoseDay',
    rules: 'decimal:2',
    type: 'currency',
  },
  {
    label: 'breadcrumb.lotto',
    icon: 'TvIcon',
    type: 'divider',
  },
  {
    key: 'maxBetTimeLotto',
    label: 'field.maxBetTime',
    rules: 'decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetGameLotto',
    label: 'field.maxBetGame',
    rules: 'decimal:2',
    type: 'currency',
  },
  {
    key: 'maxWinDayLotto',
    label: 'field.maxWinDay',
    rules: 'decimal:2',
    type: 'currency',
  },
  {
    label: 'breadcrumb.betSetting',
    icon: 'SettingsIcon',
    type: 'divider',
    resource: 'bet-setting',
    action: 'update',
  },
  {
    key: 'cutAmountPerBet',
    label: 'field.cutAmountPerBet',
    rules: 'decimal:2',
    type: 'currency',
    resource: 'bet-setting',
    action: 'update',
    class: 'data-form',
  },
  {
    key: 'cutPercentPerBet',
    label: 'field.cutPercentPerBet',
    rules: 'decimal:2',
    type: 'currency',
    resource: 'bet-setting',
    action: 'update',
    class: 'data-form',
  },
  {
    key: 'cutMaxPayout',
    label: 'field.cutMaxPayout',
    rules: 'decimal:2',
    type: 'text',
    resource: 'bet-setting',
    action: 'update',
    class: 'data-form',
  },
  {
    key: 'cutMinPayout',
    label: 'field.cutMinPayout',
    rules: 'decimal:2',
    type: 'text',
    resource: 'bet-setting',
    action: 'update',
    class: 'data-form',
  },
  {
    key: 'cutType',
    label: 'field.cutType',
    rules: '',
    type: 'radio',
    options: [
      {
        value: 'bet',
        text: 'general.bet',
      },
      {
        value: 'game',
        text: 'general.game',
      },
    ],
    resource: 'bet-setting',
    action: 'update',
  },
  {
    key: 'isCutPercent',
    label: 'field.isCutPercent',
    rules: '',
    type: 'checkbox',
    cols: 2,
    resource: 'bet-setting',
    action: 'update',
  },
  {
    label: 'breadcrumb.secondBetSetting',
    icon: 'SettingsIcon',
    type: 'divider',
    resource: 'bet-setting',
    action: 'update',
  },
  {
    key: 'cutSecondPercentPerBet',
    label: 'field.cutSecondPercentPerBet',
    rules: 'decimal:2',
    type: 'currency',
    resource: 'bet-setting',
    action: 'update',
    class: 'data-form',
  },
  {
    key: 'cutSecondMinPayout',
    label: 'field.cutSecondMinPayout',
    rules: 'decimal:2',
    type: 'text',
    resource: 'bet-setting',
    action: 'update',
    class: 'data-form',
  },
  {
    key: 'isCutSecondPercent',
    label: 'field.isCutSecondPercent',
    rules: '',
    type: 'checkbox',
    cols: 2,
    resource: 'bet-setting',
    action: 'update',
  },
  {
    label: 'breadcrumb.accountInformation',
    icon: 'LockIcon',
    type: 'divider',
  },
  {
    key: 'parentId',
    label: 'field.underAccount',
    rules: 'required',
    type: 'nAsynSingleSelection',
    repository: 'agent',
    selectionKey: 'id',
    selectionLabel: 'username',
  },
  {
    key: 'username',
    label: 'field.username',
    rules: 'required',
    type: 'text',
  },
  {
    key: 'password',
    name: 'new-password',
    label: 'field.password',
    rules: 'required|min:6',
    type: 'password',
    hideInView: true,
  },
  {
    key: 'retypePassword',
    label: 'field.retypePassword',
    rules: 'required|confirmed:password',
    type: 'password',
    hideInView: true,
  },
  {
    key: 'isEnable',
    label: 'status.active',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
  },
  {
    key: 'isClaimReward',
    label: 'field.canClaimReward',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
    resource: 'bet-setting',
    action: 'update',
  },
  {
    key: 'isReadonly',
    label: 'status.readonly',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
  },
]
