<template>
  <div>
    <load-profile />
    <!-- Table Container Card -->
    <b-card>
      <validation-observer
        ref="createForm"
        #default="{ invalid }"
      >
        <!-- form -->
        <n-form-confirmation
          key="createForm"
          :form="$refs.createForm"
          :disabled="invalid || checkUsernameLoading"
          @submit="submit"
        >
          <n-input
            v-model="data"
            :fields="fields"
            :init-value="initData"
          >
            <template #username="item">
              <b-row>
                <b-col :sm="6">
                  <b-form-group :label-for="item.field.key">
                    <validation-provider
                      #default="{ errors }"
                      :vid="item.field.key"
                      :name="$t(item.field.label)"
                      :rules="item.field.rules"
                    >
                      <label>
                        {{ $t(item.field.label) }}
                      </label>
                      <input
                        v-model="data[item.field.key]"
                        type="hidden"
                        :name="item.field.key"
                      >
                      <b-row>
                        <n-single-select
                          v-model="username.first"
                          :options="alphabet"
                          :clearable="false"
                          :loading="checkUsernameLoading"
                          class="col-6"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                        />
                        <n-single-select
                          v-model="username.second"
                          :options="alphabet"
                          :clearable="true"
                          :loading="checkUsernameLoading"
                          class="col-6"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                        />
                      </b-row>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </template>
          </n-input>
          <b-row>
            <b-col
              cols="12"
              class="text-right"
            >
              <n-button-loading
                v-if="$can('create', 'company')"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mt-1 mr-1"
                :loading="loading"
                :disabled="invalid || checkUsernameLoading"
              >
                {{ $t("button.saveChanges") }}
              </n-button-loading>
              <n-button-loading
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                :loading="loading"
                class="mt-1"
                @submit="back"
              >
                {{ $t("button.back") }}
              </n-button-loading>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BFormRadioGroup,
  BSpinner,
} from 'bootstrap-vue'
import Repository from '@/repositories/RepositoryFactory'
import Ripple from 'vue-ripple-directive'
import NButtonLoading from '@/components/NButtonLoading'
import NSingleSelect from '@/components/NSingleSelect'
import NFormConfirmation from '@/components/NFormConfirmation'
import NInput from '@/components/NInput'
import Alphabet from '@/data/alphabet.js'
import FormInput from './formInput'

const CompanyRepository = Repository.get('company')

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BFormRadioGroup,
    NButtonLoading,
    NFormConfirmation,
    NInput,
    BSpinner,
    NSingleSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        channelIds: [],
        name: null,
        phone: null,
        username: null,
        password: null,
        commission: 0,
        share: 0,

        // minBetTime: 0,
        // maxBetTime: 0,
        // maxBetGame: 0,
        // maxDrawBetGame: 0,
        // maxWinDay: 0,
        //
        // minBetTimeTwo: 0,
        // maxBetTimeTwo: 0,
        // maxBetGameTwo: 0,
        // maxBetDrawPerGameTwo: 0,
        // maxWinDayTwo: 0,
        //
        // minBetTimeBacar: 0,
        // maxBetTimeBacar: 0,
        // maxBetGameBacar: 0,
        // maxBetDrawPerGameBacar: 0,
        // maxWinDayBacar: 0,
        //
        // minBetTimeBacarTwo: 0,
        // maxBetTimeBacarTwo: 0,
        // maxBetGameBacarTwo: 0,
        // maxBetDrawPerGameBacarTwo: 0,
        // maxWinDayBacarTwo: 0,
        //
        // maxBetAmountParley: 0,
        // minKuBetParley: 0,
        // maxKuBetParley: 0,
        // maxBetAmountSingleParley: 0,
        //
        // maxWinDayJoker: 0,
        // maxLoseDayJoker: 0,
        //
        // maxBetTimeLotto: 0,
        // maxBetGameLotto: 0,
        // maxWinDayLotto: 0,

        isCanSingleBet: false,
        isCanParleyBet: false,
        ccy: null,
        isEnable: true,
        isReadonly: false,
      },
      initData: {},
      loading: false,
      checkUsernameLoading: false,
      usernameValid: false,
      username: {
        first: null,
        second: null,
      },
    }
  },
  watch: {
    'username.first': function (value) {
      this.data.username = `${this.username.first}${
        this.username.second ? this.username.second : ''
      }`
    },
    'username.second': function (value) {
      if (this.username.first) {
        this.data.username = `${this.username.first}${
          this.username.second ? this.username.second : ''
        }`
      }
    },
    'data.username': function (value) {
      if (value) {
        this.checkUsername(value)
      }
    },
  },
  mounted() {
    this.getSuggestUsername()
    if (this.$route.query.id) {
      this.show()
    }
  },
  methods: {
    show() {
      CompanyRepository.show(this.$route.query.id).then(response => {
        delete response.data.data.username

        this.initData = {
          ...response.data.data,
        }
      })
    },
    submit() {
      if (!this.usernameValid) {
        return
      }
      this.$refs.createForm.validate().then(success => {
        if (success) {
          this.loading = true
          CompanyRepository.create(this.data)
            .then(response => {
              this.$router.push({
                name: 'view-company',
                params: { id: response.data.data.id },
              })
            })
            .catch(error => {
              if (error.response?.status == 422) {
                this.$refs.createForm.setErrors(
                  error.response?.data?.errors,
                )
              }
            })
            .then(() => {
              this.loading = false
            })
        }
      })
    },
    getSuggestUsername() {
      this.checkUsernameLoading = true
      CompanyRepository.getSuggestUsername()
        .then(response => {
          const username = response.data?.data?.username
          if (username) {
            this.username.first = username.charAt(0)
            this.username.second = username.charAt(1)
          }
        })
        .catch()
        .then(() => {
          this.checkUsernameLoading = false
        })
    },
    checkUsername(username) {
      this.checkUsernameLoading = true
      CompanyRepository.checkUsername(username)
        .then(response => {
          if (response.data?.data?.used) {
            this.$refs.createForm.setErrors({
              username: this.$t('validation.unique', {
                field: this.$t('field.username'),
              }),
            })
            this.usernameValid = false
          } else {
            this.usernameValid = true
          }
        })
        .catch()
        .then(() => {
          this.checkUsernameLoading = false
        })
    },
    back() {
      this.$router.back()
    },
  },
  setup() {
    const fields = FormInput.map(value => {
      if (value.key === 'password' || value.key === 'retypePassword') {
        value.rules = value.rules.replace('required|', '')
        value.rules = value.rules.replace('required', '')
        value.rules = value.rules.replace('', 'required|')
      } else if (value.key === 'givenCredit') {
        value.disabled = false
      }

      return value
    })

    const alphabet = [...Alphabet]
    return { fields, alphabet }
  },
}
</script>
