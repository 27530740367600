export default [
  {
    key: 'state',
    label: 'field.an',
    type: 'radio',
    options: [
      { text: 'A', value: 'A' },
      { text: 'N', value: 'N' },
    ],
    cols: 6,
  },
]
