<template>
  <b-row>
    <b-col
      v-for="field in fields"
      :key="field.key"
      :lg="field.cols ? field.cols : 2"
    >
      <slot
        :name="field.key"
        :field="field"
      >
        <b-form-group
          :label="field.type !== 'checkbox' ? $t(field.label) : ''"
          :label-for="field.key"
        >
          <b-form-input
            v-if="field.type === 'text'"
            :id="field.key"
            :ref="field.key"
            v-model="data[field.key]"
            :name="field.key"
            :placeholder="$t(field.label)"
            @change="onChange"
          />
          <!--          <n-date-input-->
          <!--            :ref="field.key"-->
          <!--            v-if="field.type === 'date'"-->
          <!--            :id="field.key"-->
          <!--            v-model="data[field.key]"-->
          <!--            :name="field.key"-->
          <!--            @change="onChange"-->
          <!--          />-->

          <b-form-datepicker
            v-if="field.type === 'date'"
            :ref="field.key"
            v-model="data[field.key]"
            :name="field.key"
            class="mb-1"
            :max="field.noMaxDate ? null : new Date()"
            :date-format-options="{
              year: 'numeric',
              month: 'short',
              day: '2-digit',
            }"
          />

          <n-async-single-select
            v-if="field.type === 'nAsynSingleSelection'"
            :ref="field.key"
            v-model="data[field.key]"
            :name="field.key"
            :init-options="data[field.key]"
            :repository="field.repository"
            :selection-key="field.selectionKey"
            :selection-label="field.selectionLabel"
            :clearable="field.clearable"
            @change="onChange"
          />
          <n-single-select
            v-if="field.type === 'singleSelect'"
            v-model="data[field.key]"
            :options="field.options"
            :reduce="field.reduce"
            :clearable="field.clearable"
            :placeholder="field.placeholder"
          />
        </b-form-group>
        <b-form-radio-group
          v-if="field.type === 'radio'"
          :id="field.key"
          :ref="field.key"
          v-model="data[field.key]"
          :disabled="field.disabled"
          @change="onChange"
        >
          <b-form-radio
            v-for="option in field.options"
            :key="option.value"
            :value="option.value"
          >{{ $t(option.text) }}</b-form-radio>
        </b-form-radio-group>
        <b-form-checkbox
          v-if="field.type === 'checkbox'"
          :id="field.key"
          v-model="data[field.key]"
          :name="field.key"
          :value="true"
          :unchecked-value="false"
          :disabled="field.disabled"
        >
          {{ $t(field.label) }}
        </b-form-checkbox>
      </slot>
    </b-col>
  </b-row>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BFormDatepicker, BFormCheckbox,
} from 'bootstrap-vue'
import NAsyncSingleSelect from '@/components/NAsyncSingleSelect.vue'
import NSingleSelect from '@/components/NSingleSelect.vue'
import NDateInput from '@/components/NDateInput.vue'

export default {
  components: {
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    NAsyncSingleSelect,
    NDateInput,
    BFormDatepicker,
    NSingleSelect,
  },
  props: {
    value: {},
    fields: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      data: {},
    }
  },
  computed: {},
  mounted() {
    if (this.value) {
      this.data = this.value
    }
  },
  methods: {
    reset() {
      this.fields.forEach(element => {
        if (
          this.$refs[element.key]
          && typeof this.$refs[element.key][0].reset === 'function'
        ) {
          this.$refs[element.key][0].reset()
        }
        this.data[element.key] = null
      })
    },
    onChange() {
      this.$emit('input', this.data)
    },
  },
}
</script>

<style scoped>
</style>
