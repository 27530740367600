import Client from '@/repositories/Clients/AxiosClient'
import Config from '@/repositories/Clients/AxiosDefaultConfig'
import { prepareSearchParams } from '@/libs/helper'

const resource = `${Config.basePath}/v1/api/admin/report`

export default {
  index(payload) {
    let params = prepareSearchParams(payload)
    params = {
      ...payload,
      ...params,
      username: payload.search,
    }
    if (payload.startDate) {
      params.filters.push({
        searchby: 'created_at',
        searchoperator: '>',
        search: `${payload.startDate} 00:00:00`,
      })
    }
    if (payload.toDate) {
      params.filters.push({
        searchby: 'created_at',
        searchoperator: '<',
        search: `${payload.toDate} 23:59:59`,
      })
    }
    return Client.post(`${resource}`, params, {
      headers: {
        action: 'list-jackpot-win-history',
      },
    })
  },
  total(payload) {
    let params = prepareSearchParams(payload)
    params = {
      ...payload,
      ...params,
      username: payload.search,
    }
    if (payload.startDate) {
      params.filters.push({
        searchby: 'created_at',
        searchoperator: '>',
        search: `${payload.startDate} 00:00:00`,
      })
    }
    if (payload.toDate) {
      params.filters.push({
        searchby: 'created_at',
        searchoperator: '<',
        search: `${payload.toDate} 23:59:59`,
      })
    }
    return Client.post(`${resource}`, params, {
      headers: {
        action: 'list-jackpot-win-history-total',
      },
    })
  },
}
