export default [
  {
    key: 'ccy',
    label: 'field.ccy',
    rules: 'required',
    type: 'nAsynSingleSelection',
    repository: 'currency',
    selectionKey: 'ccy',
    selectionLabel: 'currency',
  },
  {
    key: 'userIds',
    label: 'breadcrumb.users',
    rules: '',
    type: 'nAsynMultiSelection',
    repository: 'member',
    selectionKey: 'id',
    selectionLabel: 'username',
  },
  {
      key: 'type',
      label: 'field.type',
      type: 'radio',
      options: [
          {text: 'field.include', value: 0},
          {text: 'field.exclude', value: 1},
      ],
      cols: 6,
  },
  {
    label: 'breadcrumb.channel1',
    icon: 'TvIcon',
    type: 'divider',
  },
  {
    key: 'minBetTime',
    label: 'field.minBetTime',
    rules: 'decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetTime',
    label: 'field.maxBetTime',
    rules: 'decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetGame',
    label: 'field.maxBetGame',
    rules: 'decimal:2',
    type: 'currency',
  },
  {
    key: 'maxDrawBetGame',
    label: 'field.maxDrawBetGame',
    rules: 'decimal:2',
    type: 'currency',
  },
  {
    key: 'maxWinDay',
    label: 'field.maxWinDay',
    rules: 'decimal:2',
    type: 'currency',
  },
  {
    label: 'breadcrumb.channel2',
    icon: 'TvIcon',
    type: 'divider',
  },
  {
    key: 'minBetTimeTwo',
    label: 'field.minBetTime',
    rules: 'decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetTimeTwo',
    label: 'field.maxBetTime',
    rules: 'decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetGameTwo',
    label: 'field.maxBetGame',
    rules: 'decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetDrawPerGameTwo',
    label: 'field.maxDrawBetGame',
    rules: 'decimal:2',
    type: 'currency',
  },
  {
    key: 'maxWinDayTwo',
    label: 'field.maxWinDay',
    rules: 'decimal:2',
    type: 'currency',
  },
  {
    label: 'breadcrumb.baccarat1',
    icon: 'TvIcon',
    type: 'divider',
  },
  {
    key: 'minBetTimeBacar',
    label: 'field.minBetTimeBacar',
    rules: 'decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetTimeBacar',
    label: 'field.maxBetTimeBacar',
    rules: 'decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetGameBacar',
    label: 'field.maxBetGameBacar',
    rules: 'decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetDrawPerGameBacar',
    label: 'field.maxDrawBetGameBacar',
    rules: 'decimal:2',
    type: 'currency',
  },
  {
    key: 'maxWinDayBacar',
    label: 'field.maxWinDayBacar',
    rules: 'decimal:2',
    type: 'currency',
  },
  {
    key: 'increaseBetRateBacarOne',
    label: 'field.increaseBetRateBacar',
    rules: 'max_value:100|decimal:2',
    type: 'currency',
    resource: 'bet-setting',
    action: 'update',
  },
  {
    label: 'breadcrumb.baccarat2',
    icon: 'TvIcon',
    type: 'divider',
  },
  {
    key: 'minBetTimeBacarTwo',
    label: 'field.minBetTimeBacar',
    rules: 'decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetTimeBacarTwo',
    label: 'field.maxBetTimeBacar',
    rules: 'decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetGameBacarTwo',
    label: 'field.maxBetGameBacar',
    rules: 'decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetDrawPerGameBacarTwo',
    label: 'field.maxDrawBetGameBacar',
    rules: 'decimal:2',
    type: 'currency',
  },
  {
    key: 'maxWinDayBacarTwo',
    label: 'field.maxWinDayBacar',
    rules: 'decimal:2',
    type: 'currency',
  },
  {
    key: 'increaseBetRateBacarTwo',
    label: 'field.increaseBetRateBacar',
    rules: 'max_value:100|decimal:2',
    type: 'currency',
    resource: 'bet-setting',
    action: 'update',
  },
  {
    label: 'breadcrumb.parley',
    icon: 'TvIcon',
    type: 'divider',
  },
  {
    key: 'maxBetAmountParley',
    label: 'field.maxBetAmountParley',
    rules: 'decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetAmountSingleParley',
    label: 'field.maxBetAmountSingleParley',
    rules: 'decimal:2',
    type: 'currency',
  },
  {
    label: 'breadcrumb.slotMachine',
    icon: 'TvIcon',
    type: 'divider',
  },
  {
    key: 'maxWinDayJoker',
    label: 'field.maxWinDay',
    rules: 'decimal:2',
    type: 'currency',
  },
  {
    key: 'maxLoseDayJoker',
    label: 'field.maxLoseDay',
    rules: 'decimal:2',
    type: 'currency',
  },
  {
    label: 'breadcrumb.lotto',
    icon: 'TvIcon',
    type: 'divider',
  },
  {
    key: 'maxBetTimeLotto',
    label: 'field.maxBetTime',
    rules: 'decimal:2',
    type: 'currency',
  },
  {
    key: 'maxBetGameLotto',
    label: 'field.maxBetGame',
    rules: 'decimal:2',
    type: 'currency',
  },
  {
    key: 'maxWinDayLotto',
    label: 'field.maxWinDay',
    rules: 'decimal:2',
    type: 'currency',
  },
]
